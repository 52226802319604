<template>
  <div>
    <br/>
    <br/>
    <Row type="flex" justify="center" :gutter="5">
      <Col :xs="20" :sm="18" :md="12" :lg="10">
        <Card>
          <p slot="title">
            <Icon type="md-person"></Icon>
            关于我
          </p>
          <p slot="extra">最后更新于:2016年10月01日</p>

          <p>
            我一直在想，我应该写点什么呢。
            <br/>首先，我想说，我是一个做技术的，是个互联网人，是个IT人。
            <br/>我
            <strong>擅长并长期从事网站搭建与开发</strong>
            <br/>同时对物联网、人工智能方面非常感兴趣，也略有研究。
            <br/>其实我并不是完全因为对这些东西感兴趣又去做这些事的，
            <br/>其实我是希望能够通过自己的努力，做出一些东西，让人们使用，去影响他们的生活，去改变这个世界，让这个世界变得更加美好。
            <br/>这是我们身上肩负的神圣而又伟大的使命，更是我的初心，也希望自己多年以后还能够保持这份初心。
            <br/><strong>我希望能改变这个世界，或者说在人们改变世界的潮流中能够有我的参与</strong>
          </p>
          <Divider/>
          <p>
            其次呢，我想说我是一个文青，或者说我是一个很矫情很普通的人。
            <br/>其实，我也不知道接下来应该怎么说，等我想好了再说吧。
            <br/>但是我想到了一点:
            <br/>
            <strong>我特别想说我平时几乎不会主动联系，并不是因为我冷漠，不珍惜，没有回忆，而是我本就孤独，所以请你不要介意。</strong>
          </p>
          <br/>
          <p align="center">
            <video
                src="https://files.xsdhy.com/video/xiaoxinyun.mp4"
                controls="controls"
                width="100%"
            ></video>
          </p>
        </Card>
      </Col>

      <Col :xs="20" :sm="18" :md="12" :lg="4">
        <Card>
          <p slot="title">
            <Icon type="md-medal"/>
            ABOUT ME
          </p>
          <img
              src="https://files.xsdhy.com/public/left-xsdhy-01.jpg"
              width="100%"
              height="100%"
              alt="about me"
          />
          <p>
            <b>Name</b>唐川
            <br/>
            <b>Motto</b>
            <br/>不积跬步无以至千里
            <br/>不积小流无以成江海。
            <br/>
            <b>Concept</b>
            <br/>我希望能够改变这个世界，或者说在人们改变世界的潮流中能够有自己的参与。
            <br/>
            <b>Conect Me</b>
            <br/>weChat:tcxsdhy
          </p>
        </Card>
      </Col>
    </Row>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    document.title = "关于我 - 消逝的红叶-我就是我，是不一样的烟火";
  },
  methods: {}
};
</script>
